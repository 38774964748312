import './SpinningPreloader.css';

const SpinningPreloder = ()=>{
    return(
        <div className='Loading'>
            <div className='circle'></div>
        </div>
    )
}

export default SpinningPreloder;