
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    priQ: [
        {
            q: "How do i start?",
            a: "Thought you’d never ask! Select the plan you’d like to try above and get started in less than five minutes."
        },
        {
            q: "Which plan is right for me?",
            a: "Just like every non-fungible token, we know that every project, brand, and team is unique. We recommend comparing the plans above to see which features you need."
        },
        {
            q: "How do i track my activities?",
            a: "Log into the  app, under Notification you’ll be able to view your usage."
        },
        // {
        //     q: "Do you offer any discounted plans?",
        //     a: "Yes, from time to time, we offer seasonal and occasional discounts for new customers."
        // },

    ]
};

export const pricingFAQs = createSlice({
    name: "pricing/faq",
    initialState,

});

export default pricingFAQs.reducer;