
import React, { Suspense, lazy, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import NavBar from "./Components/NavBar/NavBar";
// import HomePage from "./Screen/HomePage/HomePage";
// import Pricing from "./Screen/Pricing/Pricing";
// import Company from "./Screen/Company/Company";
// import SignUp from "./Screen/SignUp/SignUp";
// import LogIn from "./Screen/LogIn/LogIn";
import Footer from "./Components/Footer/Footer";
import SpinningPreloder from "./Components/SpinningPreloader/SpinningPreloader";
// import ProtectedRoute from "./Screen/ProtectedRoute/ProtectedRoute";

const HomePage = lazy(()=> import('./Screen/HomePage/HomePage'));
const Pricing = lazy(()=> import('./Screen/Pricing/Pricing'));
const Company = lazy(()=> import('./Screen/Company/Company'));
const SignUp = lazy(()=> import('./Screen/SignUp/SignUp'));
const LogIn = lazy(()=> import('./Screen/LogIn/LogIn'));
const ProtectedRoute = lazy(()=> import('./Screen/ProtectedRoute/ProtectedRoute'));

// import RouteGuard from "./RouteGuard/RouteGuard";
// import { history } from "./helpers/history";


const  RoutedComponents = ()=>{

    // const [token, setToken] = useState();
    // if(!token){
    //     return <LogIn />
    // }
    
    return(<>
    
        <NavBar />

        <p className='empty_paragrapgh'></p>

        <main>

            <Suspense fallback={ <SpinningPreloder /> }>
                <Routes>
                    <Route path="/" element={ <Navigate to={'/home'} /> } />
                    <Route path="home" element={ <HomePage /> } />
                    <Route path="pricing" element={ <Pricing />  } />
                    <Route path="about" element={ <Company /> } />
                    <Route path="sign_up" element={ <SignUp /> } />
                    <Route path="login" element={ <LogIn /> } />
                    <Route path="*" element={ <h2>404 page</h2>} />
                    <Route path="dashboard" element={ <ProtectedRoute /> } />

                </Routes>
            </Suspense>


        </main>

        <Footer />

    </>)
};

export default RoutedComponents;