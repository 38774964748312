
import React from "react";
import styles from './MobileNavBar.module.css';


import { AiOutlineClose } from 'react-icons/ai';
import { IoIosArrowForward } from 'react-icons/io'
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const MobileNavBar = (props)=>{

    const navigate = useNavigate();

    const app_name = useSelector(state => state.name_of_app.val);

    const { tog } = props;
    
    return(<div 
        className={styles.mob_sd_nv_br_cont} 
        onClick={tog}
        >
        
        <article className={styles.nav_art} onClick={e=> e.stopPropagation()}>

            
            <section className={styles.ht_nv_br_}>

                <div className={`${styles.nv_mb__fx} ${styles.mbmbmbmb}`}>
                    <h3 className={styles.nv_h3_} onClick={()=> {
                        navigate('/');
                        tog();
                    }}>{`${app_name}`}</h3>
                    <AiOutlineClose 
                        onClick={tog} 
                        className={`${styles.close_mn_bar} ${styles.nv_br_clz}`}
                        style={{fontSize: '25px'}}
                    />
                </div>

                <div className={styles.nv_mb__fx} onClick={()=>{
                            navigate('/');
                            tog();
                        }}>
                    <p className={`${styles.nv_h3_} ${styles.nv_ppp}`}>{`Manor`}</p>
                    <IoIosArrowForward 
                        className={styles.close_mn_bar}
                        
                    />
                </div>

                <div className={styles.nv_mb__fx} onClick={()=>{
                            navigate('/pricing');
                            tog();
                        }}>
                    <p className={`${styles.nv_h3_} ${styles.nv_ppp}`}>{`Pricing`}</p>
                    <IoIosArrowForward 
                        className={styles.close_mn_bar}
                    />
                </div>

                <div className={styles.nv_mb__fx} onClick={()=>{
                            navigate('/about');
                            tog();
                        }}>
                    <p className={`${styles.nv_h3_} ${styles.nv_ppp}`}>{`Company`}</p>
                    <IoIosArrowForward 
                        className={styles.close_mn_bar}
                    />
                </div>

                <div className={styles.mb_nv_br_btn}>
                    <button className={`${styles.nv_br_bbb} ${styles.mb_nv_lg_in}`} onClick={()=> {
                        navigate('/login');
                        tog();
                    }}>Log in</button>
                    <button className={`${styles.nv_br_bbb} ${styles.mb_nv_gn_up_}`} onClick={()=> {
                        navigate('/sign_up');
                        tog();
                    }}>Sign up</button>
        
                </div>

            </section>


            
        </article>

    </div>)
};

export default MobileNavBar;