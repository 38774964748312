
import React from "react";
import styles from './Footer.module.css';

import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

const Footer = ()=>{

    const appName = useSelector(state=> state.name_of_app.val);
    return(<div className={styles.fter_comp}>
        
        <section className={styles.ul_futa_cnt_}>

            <ul className={styles.futa_ul}>
                <li>
                    <h2 className={styles.futa_hh22}>{`${appName}`}</h2>
                </li>
                <li>
                    <p className={styles.futa_desc_p}>
                        Helping you grow your <br/> 
                        finance on a large scale, <br/>
                        Get started and become <br/>
                        part of our community.
                    </p>
                </li>
            </ul>

            <ul className={styles.futa_ul}>
                <li>
                    <h2 className={styles.futa_hh22}>Features</h2>
                </li>

                <li>
                    <NavLink to={'/about'} className={styles.futa_anko}>
                        Overview
                    </NavLink>
                </li>

                <li>
                    <NavLink to={'/'} className={styles.futa_anko}>
                        Roadmap
                    </NavLink>
                </li>

                <li>
                    <NavLink to={'/'} className={styles.futa_anko}>
                        Status
                    </NavLink>
                </li>

                <li>
                    <NavLink to={'/'} className={styles.futa_anko}>
                        Mission
                    </NavLink>
                </li>
            </ul>

            <ul className={styles.futa_ul}>
                <li>
                    <h2 className={styles.futa_hh22}>
                        Resources
                    </h2>
                </li>

                <li>
                    <NavLink to={'/'} className={styles.futa_anko}>How it works</NavLink>
                </li>

                <li>
                    <NavLink to={'/'} className={styles.futa_anko}>Investments</NavLink>
                </li>
                <li>
                    <NavLink to={'/'} className={styles.futa_anko}>Customers</NavLink>
                </li>
            </ul>
        </section>

        <hr className={styles.futa_hr_}/>

        <article className={styles.lst_lst_}>
            <p>2023 {appName} All Right Reserved</p>

            <p>Privacy Policy</p>

            <p>Terms & Conditions</p>
        </article>

    </div>)
}

export default Footer;