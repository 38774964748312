
import { createSlice } from '@reduxjs/toolkit';

export const appNameslice = createSlice({
    name: 'app_name',
    initialState: { val: "Fundlet"},
    reducers: {}
});


export default appNameslice.reducer;