
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    goq: [
        {
            q: "What payment methods do you acccept?",
            a: "We accepts all major credit and debit cards. Though we do not have the ability to accept payment in crypto yet, stay tuned–we’re working to make this possible in the future."
        },
        {
            q: "What if i need to change my plan?",
            a: "No problem! If you need to change your plan, simply log into your account and head to pricing. Under pricing, select the new plan you’d like to use and you should see the change reflected by the next billing cycle."
        },
        {
            q: "If I'm inactive for so long what happens?",
            a: "Your account and investments are completley secure and safe. Whether you are active or idle we'll let your money work for you."
        },
        {
            q: "Do you offer any discounted plans?",
            a: "Yes, from time to time, we offer seasonal and occasional discounts for new customers."
        },

    ]
};

export const freqQ = createSlice({
    name: "freq questions",
    initialState,
    reducers: {}
});


export default freqQ.reducer;