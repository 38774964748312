import { configureStore } from "@reduxjs/toolkit";
import appNameslice from "./features/appNameSlice";
import authSliceLogin from "./features/authSliceLogin";
import freqQ from "./features/frequentquestions";
import navBarSlice from "./features/navBarSlice";
import pricingFAQs from "./features/pricingFAQs";


const store = configureStore({
    reducer: {
        name_of_app: appNameslice,
        nav_bar: navBarSlice,
        faq: freqQ,
        priceFAQ: pricingFAQs,
        user: authSliceLogin
    }
});

export default store;