
import React from 'react';
import './App.css';
import RoutedComponents from '../RoutedComponents';

function App() {

  return (
    <div className="App">
      
      <RoutedComponents />

    </div>
  );
}

export default App;




