
import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    mid_nav: [
        {
            name:'Manor',
            link: 'home'
        },
        {
            name:'Pricing',
            link:'pricing'
        },
        {
            name:'Company',
            link:'about'
        },
    ],

    data_acct: [
        {
            name: 'Sign-In',
            link: 'sign'
        },
        {
            name: 'Get Started',
            link: 'create-account'
        }
    ]
};


export const navBarSlice = createSlice({
    name: "navbar",
    initialState
});

export default navBarSlice.reducer;