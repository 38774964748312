
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import SignUp from "../Screen/SignUp/SignUp";
import axios from 'axios';

// export const logInUser = createAsyncThunk("loginuser", async (body)=>{
//     const logInEndPoint = "https://deflux.goldenskyspms.org/user/account/login";
//     const res = await fetch(logInEndPoint, {
//         method: "post",
//         headers: {
//             "Content-Type": "application/json"
//         },
//         body: JSON.stringify(body)
//     })
//     return await res.json();
// });


export const logInUser = createAsyncThunk(
    'data/fetchData',
    async ({ token, otherParams }) => {
      const response = await axios.get('https://deflux.goldenskyspms.org/user/account/login', {
        headers: {
          'Authorization': 'Bearer ' + token
        },
        params: otherParams
      });
      return response.data;
    }
  );



const initialState = {
    msg: "",
    user: "",
    token: null,
    loading: null,
    error: null,
    // invalidCredentials: false
};

const authSliceLogin = createSlice({
    name: 'auth',
    initialState,
    reducers:{

        addToken: (state,action)=>{
            state.token = localStorage.getItem("token");
        },
        addUser: (state, action)=>{
            state.user = localStorage.getItem("user")
        },
        logOut: (state,action)=>{
            state.token = null;
            localStorage.clear();
        }

    },
    extraReducers: (builder) =>{
        builder
        .addCase(logInUser.pending, state=>{
            state.loading = true;
            state.error = null;
            state.token = null;
        })
        .addCase(logInUser.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            state.token = action.payload;
            // console.log(state.token)
          })
          .addCase(logInUser.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
            state.token = null;
          });
    }
});


export const { addToken, addUser, logOut } = authSliceLogin.actions;
export default authSliceLogin.reducer;