
import styles from './NavBar.module.css';
import MobileNavBar from '../MobileNavBar/MobileNavBar';

import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { AiOutlineMenuUnfold } from 'react-icons/ai';
import { useState } from 'react';

const NavBar = ()=>{

    const consoleStyle = "color: orange; font-size: 30px;"
    console.log("%c WARNING!!!", consoleStyle);
    const browser = "color: #833AB4; font-size: 11px;";
    console.log("%c This browser feature is for developers only. Please do not copy-paste any code or run any scripts here. It may cause your account to be compromised", browser)
    console.log("%c For more information, https://en.wikipedia.org/wiki/Self-XSS", browser)

    let activeStyle = {
        color: '#833AB4'
      };

    const [mobileNavBar, setMobileNavbar] = useState(false);
    const toggleNavBar = ()=> setMobileNavbar(mobileNavBar=> !mobileNavBar);

    const appName = useSelector( state=> state.name_of_app.val);
    const { mid_nav } = useSelector(state => state.nav_bar);

    return(<>

        { !!mobileNavBar ? <MobileNavBar tog={toggleNavBar} /> : null }
    <nav className={styles.nav_bar}>

        <div className={styles.app_title_cont}>
            {/* <h3> */}
                <NavLink className={styles.logo_title} to={`/`}>
                    {`${appName}`}
                </NavLink>
            {/* </h3> */}
        </div>

        <article className={styles.menu_bbar} onClick={toggleNavBar} >
            <AiOutlineMenuUnfold 
            className={styles.menu_icon_} 
            />
        </article>

        <div className={styles.mid_nav_bar_cont}>
            <ul className={styles.nav_bar_ul}>
                {
                    mid_nav.map( (val, ind)=> <li key={ind} className={styles.nav_bar_list}>
                        <NavLink to={val.link} className={styles.nav_bar_link} style={({ isActive }) =>
              isActive ? activeStyle : undefined
            }>
                            {`${val.name}`}
                        </NavLink>
                    </li>)
                }
            </ul>
        </div>


        <div className={styles.acct_nav_bar_cont}>
            <ul className={styles.nav_bar_ul}>
              <li className={styles.nav_bar_list}>
                <NavLink to={`login`} className={`${styles.nav_bar_link} ${styles.nav_bar_log_in}`} style={({ isActive }) =>
              isActive ? activeStyle : undefined
            }>
                    Login
                </NavLink>
              </li>

              <li className={styles.nav_bar_list}>
                <NavLink to={`sign_up`} className={`${styles.nav_bar_link} ${styles.nav_bar_get_started}`}>
                    Sign Up
                </NavLink>
              </li>
            </ul>
        </div>

    </nav></>)
};

export default NavBar;