
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './Container/App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import store from './store';
import { Provider } from 'react-redux';
import { Auth0Provider } from '@auth0/auth0-react';

import { ApiProvider } from '@reduxjs/toolkit/query/react';
import { productsApi } from './features/apiSlice';
import Data from './Data';

// const authODomainID = "dev-vdjn537g.auth0.com";
// const authOClientID = "vdlyuaQQD4uPUmLHFyk9F6vJaWqJPYXb";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <BrowserRouter>

        <Provider store={store}>

          {/* <ApiProvider api={productsApi}> */}

              {/* <Data /> */}
              {/* <Auth0Provider 
                domain={authODomainID}
                clientId={authOClientID}
                authorizationParams={{
                  redirect_uri: window.location.origin
                }}
              > */}
              
                  <App />

              {/* </Auth0Provider> */}


            {/* </ApiProvider> */}

        </Provider>
        
      </BrowserRouter>
      
);

reportWebVitals();
